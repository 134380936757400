<template>
  <div v-if="on">
    <h5 @click="changeCat(cattwo)" class="cattwo-name-on">{{ cattwo }}</h5>
  </div>
  <div v-else>
    <h5 @click="changeCat(cattwo)" class="cattwo-name-off">{{ cattwo }}</h5>
  </div>
</template>


<style scoped>
  .cattwo-name-on {
    border: 1px solid #333; 
    font-weight:600;
    
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 16px;
    text-align: center;
    cursor: pointer;  
    margin: 4px 8px 4px 8px;
    padding: 4px 8px;
  }
  body.body--dark .cattwo-name-on {
    color: #333; /* 158bff, 1976d2 */
  }

  .cattwo-name-off {        
    box-sizing: border-box;    
    border-radius: 16px;
    text-align: center;
    cursor: pointer;  
    margin: 4px 8px 4px 8px;
    padding: 4px 8px;
  }

  .cattwo-name-off:hover{
    background-color: #eee;
    transform: translateY(-1px);
  }
  body.body--dark .cattwo-name-off:hover {
    color: #333; /* 158bff, 1976d2 */
  }

  @media (max-width: 720px) { 
    .cattwo-name-on { 
      font-size:1rem;
      padding: 2px 4px;
      margin: 4px 4px 4px 4px;
    }
    .cattwo-name-off { 
      font-size:1rem;
      padding: 2px 4px;
      margin: 4px 4px 4px 4px;
    }
  }

  @media (max-width: 500px) { 
    .cattwo-name-on { 
      font-size:0.75rem;
      padding: 1px 2px;
      
    }
    .cattwo-name-off { 
      font-size:0.75rem;
      padding: 1px 2px;
    }
  }

</style>

<script setup>
  const emit = defineEmits(['catChg']);
  const changeCat = (newcat) =>{
    emit('catChg', newcat);    
  };

  defineProps({
    id:Number,
    cattwo:String,
    on:Boolean,
  });

</script>
