
import './styles/quasar.scss'
import '@quasar/extras/roboto-font/roboto-font.css'

// To be used on app.use(Quasar, { ... })
export default {
  config: {    
  },
  plugins: {
  }
}