<template>
  <div class="about-us" >
    <h2 class="about-title-h2">The #1 Directory of BackLink Checker Tools</h2>
    <h4 class="about-title-h4">Curated with love by <a href="https://x.com/toolsie_" class="bldr-external-link">@toolsie_</a></h4>
    <h5 class="about-title-h5">
    <p>On a tight budget but need quick access to a Backlink Checker? Don't want to sign-up to a service you will only use occasionally? Then you're in the right place.</p>
    <p>Gathered here today are all the Backlink Checker tools we can find - researched and ranked, categorized and curated. Handily displayed in one easy to use directory.</p>
    <p>I hope you find this service useful and thank you for stopping by!</p>
    </h5>
    <div class="about-img-wrapper">
      <a class="about-social" href="https://x.com/toolsie_">
      <img class="about-img" src="../assets/toolsie.png"/>
      <q-badge class="about-badge" color="cyan-8" label="@toolsie_"></q-badge>
      </a>
    </div>

  </div>
  
</template>

<style scoped>

  .about-us{
    text-align: center;
    margin: 100px auto;
    
    max-width:800px;
  }
  .about-img-wrapper{
    text-align: center;    
    margin: 0 auto 24px auto;    
    display:flex;
    flex-direction: column;
    width: 100px;

  }
  .about-img{    
    width: 100px;
    border-radius: 100px;
    height: auto;    
    border: 3px solid #392b4b;
    box-shadow: 0 24px 40px -10px rgba(0, 0, 0, 0.2), 0 32px 56px -16px rgba(0, 0, 0, 0.2);
    margin:0 0px 0 0px;
  }
  .about-badge{
    vertical-align: top;
    margin:-2px auto 0 auto;
  }

  .about-title-h2 {
    letter-spacing: unset;
    padding:unset;
    margin-block-end: 0px;
    margin-block-start: 0px;
    font-weight: 500;
    line-height:1.2em !important;
    font-size: 4rem;  
    color: #474747;
    margin:0 20px 20px 20px;
  }
  .about-title-h4{
    padding:unset;
    font-size: 2rem;
    margin:0 20px 64px 20px;
  }
  .about-title-h5{
    padding:0 24px 0 24px;
  }
  @media (max-width: 620px) { 
    .about-title-h2 {
      font-size: 3rem; 
      padding:0 20px 0 20px;
      margin-bottom: 10px;
    }
    .about-title-h4 {
      font-size: 1.5rem; 
      padding:0 20px 0 20px;
      margin-bottom: 48px;
    }
    .about-title-h5{
      font-size:1rem;
      padding:0 20px 0 20px;
    }
  }
  body.body--dark .about-title-h2 {
    color: #333; /* 158bff, 1976d2 */
  }
  body.body--dark .about-title-h4 {
    color: #444; /* 158bff, 1976d2 */
  }
  body.body--dark .about-title-h5 {
    color: rgb(68,68,68); /* 158bff, 1976d2 */
  }

</style>

<script setup>

    import { onMounted } from 'vue';
    
    onMounted(async () => {
        
    });

</script>