<template>
                          
    <h1 class="bldr-title-h1">BackLink Checker List</h1>
    <h3 class="bldr-title-h3">Discover the best Backlink Checkers ranked by Popularity. Free, paid, & limited-use tools included.</h3>
    
    <div class="items-catone">
      <CatOne
        v-for="dta in catones"
        :key="dta.id"          
        :catone="dta.cat"   
        :on="dta.on"       
        @catChg = "changeCatOne"
      />
    </div>

    <div class="items-cattwo">
      <h5 v-if="basedta!==undefined" class="items-cattwo-title">Lookups</h5>
      <CatTwo
        v-for="dta in cattwos"
        :key="dta.id"          
        :cattwo="dta.cat"   
        :on="dta.on"       
        @catChg = "changeCatTwo"
      />
    </div>

    <div class="items-grid">
      <WebBox
        v-for="dta in data"
        :key="dta.id"          
        :name="dta.name"
        :title="dta.title"
        :icon="dta.icon"
        :description="dta.description"
        :data1="dta.data1"
        :url="dta.url"
        :cat1="dta.cat1"
        :cat2="dta.cat2"
        :featured="dta.featured"
      />
    </div>
      
    <div class="submit-wrapper" id="submitForm">
      <SubmitForm
        :projUrl="projUrl"
      />    
    </div>

    <div class="about-wrapper" id="aboutUs">
      <AboutUs        
      />    
    </div>

    <div class="faq-wrapper" id="questionsAnswers">
      <QuestionsAnswers        
      />    
    </div>

</template>

<style scoped>

.bldr-title-h1 {
  letter-spacing: unset;
  padding:unset;
  margin-block-end: 0px;
  margin-block-start: 0px;
  font-weight: 500;
  line-height:1.2em !important;
  font-size: 5rem;  
  color: #474747;
  margin-bottom: 20px;
  text-align: center;
}
.bldr-title-h3{
  padding:unset;
  margin-top:0px;
  margin-bottom: 64px;
  font-size: 1.75rem;
  max-width:800px;
  text-align: center;
  line-height:2.75rem;
}

@media (max-width: 620px) { 
  .bldr-title-h1 {
    font-size: 3rem; 
    margin-bottom: 20px;
    padding: 0 10px 0 10px;
  }
  .bldr-title-h3 {
    font-size: 1.3rem; 
    max-width:400px;
    margin-bottom: 32px;
    line-height:1.55rem;
  }
}
body.body--dark .bldr-title-h1 {
  color: #fff; /* 158bff, 1976d2 */
}
body.body--dark .bldr-title-h3 {
  color: #ddd; /* 158bff, 1976d2 */
}

.items-catone{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  margin: 0px 20px 28px 20px;
  padding: 0 0 ;
  border-radius: 16px;
}
.items-cattwo{
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border: 1px solid #ccc;
  margin: 0px 20px 28px 20px;
  border-radius: 16px;
}
.items-cattwo-title{
  color:#666;
  background-color: #eee; 
  font-size: 1rem;
  margin: 0px 0px 0px 0px;
  padding: 8px 8px 8px 12px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-right: 1px dotted #ccc;
}

@media (max-width: 620px) { 
  .items-catone{
    margin: 0px 5px 20px 5px;
  }
  .items-cattwo{
    margin: 0px 5px 20px 5px;
  }
  .items-cattwo-title{
    font-size:0.8rem;
    padding: 6px 8px;
  }
}

.items-grid {
  display: flex;
  flex-wrap:wrap;
  
  margin: 0 20px 0 20px ;
}

.items-grid > * {
  flex: 1 1 calc(25% - 16px);
  margin: 8px;
}

@media (max-width: 768px) {
  .items-grid > * {
    flex: 1 1 calc(50% - 16px);
  }
}
@media (max-width: 500px) {
  .items-grid > * {
    flex: 1 1 100%;
  }
}
@media (max-width: 620px) {
  .items-grid{
    margin-right: 8px;
    margin-left: 8px;
  }
}

.submit-wrapper{
  margin: 0px 20px 0px 20px;
}
@media (max-width: 620px) {
  .submit-wrapper{
    margin-right: 8px;
    margin-left: 8px;
  }
}
.about-wrapper{
  background-color: #dde8f7;
  width:100%;
  margin: 0px 0px;
}
.faq-wrapper{
  width:100%;
  margin: 0px 0px;
  padding:0 20px 0 20px;
}

</style>

<script setup>
  import { ref, onMounted } from 'vue';
  import { fetchIPX } from '@/utils';
  import WebBox from '../components/WebBox.vue';
  import CatOne from '../components/CatOne.vue';
  import CatTwo from '../components/CatTwo.vue';
  import SubmitForm from '../components/SubmitForm.vue';
  import AboutUs from '../components/AboutUs.vue';
  import QuestionsAnswers from '../components/QuestionsAnswers.vue';
  import {useRoute} from "vue-router";

  const route = useRoute();
  //const projName = ref(route.meta.projName);
  const projUrl = ref(route.meta.projUrl);
  //const projEmail = ref('route.meta.projEmail');

  //data from server are arrays
  const data = ref([]);
  const basedta = ref([]);
  const tmpdta = ref([]);
  const otmpdta = ref([]);
  
  //cats are objects
  const catones = ref({});
  const baseCatOne = ref({});
  const cattwos = ref({});
  const baseCatTwo = ref({});

  const baseCatAttrib = {
    id:'',
    cat:'',
    on:false,
  };

  const alwaysshowFeatured = ref(true);

  const changeCatOne = (newcat) =>{
    var cat, x;
    
    if (newcat=='ALL'){      
      tmpdta.value = [...basedta.value];  //makes a copy of the array and assign to tmpdta, as an array is a reference
    }else{
      tmpdta.value = [];                  //initialise tmpdta so it is empty again (otherwise there would be left over entries from previous) 
      var cnt = -1;
      for (x = 0;x<basedta.value.length;x++){
        cat = basedta.value[x].cat1;
        if (cat==newcat || (alwaysshowFeatured.value && basedta.value[x].featured==1)){
          cnt++;
          tmpdta.value[cnt] = basedta.value[x];
        }
      }
    }

    for (const key of Object.keys(baseCatOne.value)){
      cat = key;
      if (cat==newcat){
        baseCatOne.value[cat].on = true;
      }else{
        baseCatOne.value[cat].on = false;
      }
    }
    if (newcat=='ALL'){      
      //all off at this point
      baseCatOne.value['ALL'].on = true;
    }

    //reset cattwo base & regen based on new catone scope
    baseCatTwo.value = {};    
    for (x = 0;x<tmpdta.value.length;x++){
      var c;

      //extract unique cattwo vals
      c = tmpdta.value[x].cat2;
      if (c!==null){
        if (baseCatTwo.value[c] === undefined){
          baseCatTwo.value[c] = {...baseCatAttrib};
          baseCatTwo.value[c].id = tmpdta.value[x].idx;
          baseCatTwo.value[c].cat = c;
        }
      }
    }

    data.value = tmpdta.value;
    catones.value = baseCatOne.value;

    cattwos.value = baseCatTwo.value;
  }

  const changeCatTwo = (newcat) =>{
    var cat, x;
    var cnt;

    otmpdta.value = [];                 //used to store temporary array before tmpdata
    tmpdta.value = [];                  //initialise tmpdta (which is used to set data)

    //first find current catone
    var catonecat = '';
    for (const key of Object.keys(baseCatOne.value)){
      if (baseCatOne.value[key].on){
        catonecat = key;
        break;
      }
    }

    //construct otmpdata to reflect current catone state
    if (catonecat=='ALL'){      
      otmpdta.value = [...basedta.value];  //makes a copy of the array and assign to tmpdta, as an array is a reference
    }else{
      otmpdta.value = [];                  //initialise tmpdta so it is empty again (otherwise there would be left over entries from previous) 
      cnt = -1;
      for (x = 0;x<basedta.value.length;x++){
        cat = basedta.value[x].cat1;
        if (cat==catonecat || (alwaysshowFeatured.value && basedta.value[x].featured==1)){
          cnt++;
          otmpdta.value[cnt] = basedta.value[x];
        }
      }
    }

    //based on current cat 2 state modify otmpdata to reflect on or off for cattwo button
    if (baseCatTwo.value[newcat].on){
      //turn off, restore values to catone
      tmpdta.value = [...otmpdta.value];
    }else{
      //turn on and restrict to cattwo
      //console.log("turn on");
      cnt = -1;
      for (x = 0;x<otmpdta.value.length;x++){
        cat = otmpdta.value[x].cat2;
        if (cat==newcat || (alwaysshowFeatured.value && otmpdta.value[x].featured==1)){
          //restrict to this cat
          cnt++;
          tmpdta.value[cnt] = otmpdta.value[x];                  
        }
      }
    }

    //set UI state
    for (const key of Object.keys(baseCatTwo.value)){
      cat = key;
      if (cat==newcat){        
        if (baseCatTwo.value[cat].on){
          baseCatTwo.value[cat].on = false;
        }else{
          baseCatTwo.value[cat].on = true;
        }
      }else{
        baseCatTwo.value[cat].on = false;      
      }
    }



    data.value = tmpdta.value;
    cattwos.value = baseCatTwo.value;

    //console.log(cattwos.value);    
    //console.log(data.value);
  }


  onMounted(async () => {

    
    //document.title = projName.value; //not right
    
    var devmode = process.env.NODE_ENV=='development';
    var pth = '';
    var cat;


    try {    
      console.log('getting data');
      if (!devmode){
        pth = projUrl.value + 'api/getdata';
      }else{
        pth = 'http://localhost:3000/api/getdata';
      }
      const p4 = fetchIPX(pth, -1, -1);      
      
      basedta.value = (await p4).dta;

      //catone
      cat = 'ALL';
      baseCatOne.value[cat] = {...baseCatAttrib};
      baseCatOne.value[cat].id = -1; //ALL id
      baseCatOne.value[cat].cat = cat;
      baseCatOne.value[cat].on = true;
      /*
      baseCatTwo.value[cat] = {...baseCatAttrib};
      baseCatTwo.value[cat].id = -1; //ALL id
      baseCatTwo.value[cat].cat = cat;
      baseCatTwo.value[cat].on = true;
      */
      for (var x = 0;x<basedta.value.length;x++){
        var c;
        basedta.value[x].icon =  '/item-images/' + basedta.value[x].icon;
        
        //extract unique catone vals
        c = basedta.value[x].cat1;
        if (c!==null){
          if (baseCatOne.value[c] === undefined){
            baseCatOne.value[c] = {...baseCatAttrib};
            baseCatOne.value[c].id = basedta.value[x].idx;
            baseCatOne.value[c].cat = c;
          }
        }

        //extract unique cattwo vals
        c = basedta.value[x].cat2;
        if (c!==null){
          if (baseCatTwo.value[c] === undefined){
            baseCatTwo.value[c] = {...baseCatAttrib};
            baseCatTwo.value[c].id = basedta.value[x].idx;
            baseCatTwo.value[c].cat = c;
          }
        }
      }

      data.value = basedta.value;
      //console.log(basedta.value);

      catones.value = baseCatOne.value;
      //console.log(baseCatOne.value);

      cattwos.value = baseCatTwo.value;
      //console.log(baseCatTwo.value);


    } catch (err) {
      console.error('Error fetching getdata:', err);
    } finally {
      console.error('finishing');
    }        
    
  });



</script>