<template>
  <div class="submit-form" >
    <div class="submit-form-titles" >
      <h2 class="submit-title-h2">Add Your BackLink Checker Tool </h2>
      <h4 class="submit-title-h4">Get quick exposure to thousands of users looking to improve their website rankings with our directory.</h4>
    </div>    

    <q-form @submit="onSubmit" class="q-gutter-md">
      <q-input
        filled
        v-model="inputEmail"
        label="Your Email *"
        type="email"
        lazy-rules
        :rules="[ (val, rules) => rules.email(val)  || 'Enter your email']"
      ></q-input>

      <q-input
        filled
        v-model="inputName"
        label="Your Name *"
        lazy-rules
        :rules="[ val => val != '' && val.length > 0 || 'Please enter your name']"
      ></q-input>

      <q-input
        filled
        v-model="inputURL"
        label="URL of your BackLink Tool *"
        type="url"
        lazy-rules
        :rules="[ val => val != '' && val.length > 0 || 'Please enter project URL']"
      ></q-input>

      <q-input
        filled
        v-model="inputDesc"
        label="BackLink Tool Description or comments *"
        type="textarea"
        lazy-rules
        :rules="[ val => val != '' && val.length > 0 || 'Please enter a project description']"
      ></q-input>

      <div>
        <q-btn label="Submit" type="submit" color="primary"></q-btn>
      </div>

    </q-form>
  </div>
  
</template>

<style scoped>

  .submit-form{
    text-align: center;
    margin: 100px 10px;
    
  }

  .submit-form-titles{
    
  }

  .submit-title-h2 {
    letter-spacing: unset;
    padding:unset;
    margin-block-end: 0px;
    margin-block-start: 0px;
    font-weight: 500;
    line-height:1.2em !important;
    font-size: 4rem;  
    color: #474747;
    margin-bottom: 20px;    
    max-width: 600px;
  }
  .submit-title-h4{
    padding:unset;
    font-size: 2rem;
    margin-top:0px;
    margin-bottom: 64px;
    font-size: 1.5rem; 
    text-align: center;
    margin: 0px auto 64px auto;
    max-width: 600px;
    width:100%;
    line-height:2rem;
  }
  @media (max-width: 620px) { 
    .submit-title-h2 {
      font-size: 3rem; 
      margin-bottom: 10px;
    }
    .submit-title-h4 {
      font-size: 1.5rem; 
      margin-bottom: 48px;
    }
  }
  body.body--dark .submit-title-h2 {
    color: #fff; /* 158bff, 1976d2 */
  }
  body.body--dark .submit-title-h4 {
    color: #ddd; /* 158bff, 1976d2 */
  }

</style>

<script setup>

    import { ref, onMounted } from 'vue';
    
    const inputEmail = ref('');
    const inputURL = ref('');
    const inputName = ref('');
    const inputDesc = ref('');

    var devmode = process.env.NODE_ENV=='development';
    var pth = '';    

    const onSubmit = async () => {

        if (!devmode){
            pth = props.projUrl + 'api/submit';
        }else{
            pth = 'http://localhost:3000/api/submit';
        }

        try {
            const response = await fetch(pth, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ inputEmail: inputEmail.value, inputName: inputName.value, inputURL: inputURL.value, inputDesc: inputDesc.value}),
            });

            const result = await response.json();
            console.log('Email send result:', result);
            
            alert('Submission Success');
            
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    onMounted(async () => {
        console.log(props.projUrl);
    });

    const props = defineProps({
        projUrl: String,
    });

</script>