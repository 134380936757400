export const copy = (txt) => {
    navigator.clipboard.writeText(txt).then( () => {
            console.log('Info copied to clipboard');
        }, (err) => {
            console.error('Could not copy Info:', err);
        }
    );
}


export const getVisState = (visState, localKey, defaultVis) => {
    const storedPreference = localStorage.getItem(localKey);
    if (storedPreference !== null) {      
      if (storedPreference === 'false'){
        visState.value = false;
      }else{
        visState.value = true;
      }
    } else {
      visState.value = defaultVis; 
      localStorage.setItem(localKey, visState.value);
    }
}
  
export const toggleVis = (visState, localKey) => {
    visState.value = !visState.value;
    localStorage.setItem(localKey, visState.value);
}

export const fetchIPX = async(url, p1, p2) => {
  try {
    //does not catch net::ERR_NAME_NOT_RESOLVED, will always output to console. Need a token to discourage backdoor calls to subdomains.
    const response = await fetch(url, {credentials: 'include', headers:{'X-Forwarded-Type-A':p1, 'X-Forwarded-Type-B':p2 }});
    console.log(response);
    const data = await response.json();      
    return data;
  } catch (err) {
    console.log(err);
    return '';
  }
}

