<template>
  
  <q-layout view="hHh lpR fFf">

    <q-header bordered class="bldr-header" >
      <q-toolbar class="bldr-toolbar">
        <router-link to="/">
        <q-avatar size="38px" class="bldr-hal-logo" square>
          <img :src="logoSource" alt="Backlinks Checker logo" width="38" height="38">
        </q-avatar>
        </router-link>
        
        <!--we need a H1 tag here somehow??-->
        
        <q-toolbar-title class="bldr-logo-title">
          <!--some kind of break for font size, also the text and teh light mode colours not right-->
          <q-item-label >BacklinkCheckers.org</q-item-label>
        </q-toolbar-title>
        <q-btn flat round dense :icon="darkModeIcon" @click="toggleDarkMode" aria-label="Toggle light/dark mode"></q-btn>
        <q-btn class="bldr-submit" outline rounded size="md" @click="scrollToForm" aria-label="Submit your site">Submit</q-btn>
      </q-toolbar>

    </q-header>

    <q-page-container>
      <div class="flex flex-center column">
        <div class="row bldr-main-container">
          <div id="parent" class="fit column wrap justify-center items-center content-center" style="overflow: visible;">
                          
        <!--insert router here (and app-->
          <router-view></router-view>
        
          </div>   
          
        </div>        

        <div class="row bldr-footer">
        <q-btn label="Contact" color="primary" @click="contact = true" no-caps unelevated class="bldr-contact-button" aria-label="show how to contact this site"></q-btn>
        <q-dialog v-model="contact">
          <q-card>
            <q-card-section class="bldr-contact-header">
              <div class="text-h6 text-center">Contact</div>
            </q-card-section>
            <q-separator></q-separator>
            <q-card-section class="row items-center bldr-contact-content">
              <q-avatar size="60px"><img src="./assets/toolsie.png" width="60" height="60"></q-avatar>
              <span class="bldr-content-text">Feedback / questions<br/><span class="bldr-content-email">{{projEmail}}</span></span>
            </q-card-section>

            <q-card-actions align="center">
              <q-btn flat label="OK" color="primary" v-close-popup></q-btn>
            </q-card-actions>
          </q-card>
        </q-dialog>


        <div class="bldr-footer-links">
          <router-link :to="{name:'Privacy Policy' }" class="bldr-footer-link">Privacy Policy</router-link>
          <router-link :to="{name:'Disclaimer'}" class="bldr-footer-link">Disclaimer</router-link>
          <router-link :to="{name:'Terms and Conditions'}" class="bldr-footer-link">Terms and Conditions</router-link>
        </div>          

        {{projCompany}}, {{projCompanyDeets}}<br />         
        {{projName}} &copy; {{projCopy}}, {{projCompany}}<br />
        Built by <a href="https://toolsie.dev/">toolsie</a>  
        
        </div>   
      </div>

    </q-page-container>
  </q-layout>    
  
  <div v-if="notdevmode">
  <!-- no use for this at mo-->
  </div>
</template>


<script setup>
  import { ref, onMounted, computed } from 'vue';
  import { matLightMode, matDarkMode } from '@quasar/extras/material-icons';
//  import logoDark from './assets/dark-logo-100.png'; //dark logo
//  import logoLight from './assets/light-logo-100.png'; //light logo
  import logoDark from './assets/logo1.png'; //dark logo
  import logoLight from './assets/logo1.png'; //light logo
  import { useQuasar } from 'quasar';
  import constants from '../../constants.js';
  
  const $q = useQuasar();  
  const logoSource = ref('');
  const isDarkMode = ref($q.dark.isActive);
  const darkModeIcon = computed(() => {
    return isDarkMode.value ? matLightMode : matDarkMode;
  });
  const toggleDarkMode = () => {
    $q.dark.toggle();
    isDarkMode.value = $q.dark.isActive;
    localStorage.setItem('darkMode', $q.dark.isActive ? 'true' : 'false');
    if ($q.dark.isActive){
      logoSource.value = logoDark;
    }else{
      logoSource.value = logoLight;
    }
  };
  const setInitialDarkMode = () => {
    const storedPreference = localStorage.getItem('darkMode');
    if (storedPreference !== null) {      
      $q.dark.set(storedPreference === 'true');
    } else {
      const isSystemDarkMode = false; //overriding local pref, dark is better!
      //const isSystemDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      $q.dark.set(isSystemDarkMode);
      //initialise local storage with system preference
      localStorage.setItem('darkMode', $q.dark.isActive ? 'true' : 'false');
    }
    //synchronise icon 
    isDarkMode.value = $q.dark.isActive;
    if ($q.dark.isActive){
      logoSource.value = logoDark;
    }else{
      logoSource.value = logoLight;
    }
  };

  const scrollToForm = () => {
    const uri = document.documentURI;
    if (uri!=projUrl.value || notdevmode.value==false){
      console.log(uri, projUrl.value);
      document.location = projUrl.value + '#submitForm';
    }else{
      const element = document.getElementById('submitForm');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }    
  }

  const contact =  ref(false);
  const notdevmode = ref(true);

  //have to do it this way, no reliable access to userouter (as its not a route)
  const projName = ref(constants.projName);
  const projCopy = ref(constants.projCopy);
  const projEmail = ref(constants.projEmail);
  const projCompany = ref(constants.projCompany);
  const projCompanyDeets = ref(constants.projCompanyDeets);
  const projUrl = ref(constants.projUrl);

  onMounted(async () => {    

    setInitialDarkMode();
    var devmode = process.env.NODE_ENV=='development';

    if (devmode){
      notdevmode.value = true;
    }

  });

  /* eslint-enable no-unused-vars */

</script>