<template>
  <div class="faq" >
    <h2 class="faq-title-h2">F A Q</h2>
    <h4 class="faq-title-h4">The meaning of life</h4>

    <div class="faq-item" >
      <q-expansion-item
        :expand-icon="matKeyboardDoubleArrowDown"
        :expanded-icon="matKeyboardDoubleArrowUp"

        label="How can I submit a site to the BackLink Checkers List?"
        class="faq-item-header"
      >
        <q-card>
          <q-card-section class="faq-item-text">
            Submission is free! Please use the form located here <q-btn class="bldr-submit" outline rounded size="md" @click="scrollToForm" aria-label="Submit your site">Form</q-btn>. We aim to list new sites within 3 days and we will contact you if any further details are needed.
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </div>

    <div class="faq-item" >
      <q-expansion-item
        :expand-icon="matKeyboardDoubleArrowDown"
        :expanded-icon="matKeyboardDoubleArrowUp"

        label="Can we have a Featured Listing?"
        class="faq-item-header"
      >
        <q-card>
          <q-card-section class="faq-item-text">
            Yes! Featured listings increase your products exposure and have proven a cost-effective method to reach new users. Please contact us using the submission form above and we will get back to you with further details. 
            <div class="faq-acme-wrapper"><img class="faq-acme-wrapper-img" src="/item-images/acme_screenshot.png" alt="Featured listing screenshot"/></div>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </div>


    <div class="faq-item" >
      <q-expansion-item
        :expand-icon="matKeyboardDoubleArrowDown"
        :expanded-icon="matKeyboardDoubleArrowUp"

        label="All about BackLink Checkers (10 answers)"
        default-opened
        class="faq-item-section-header"
      >
        <q-card>
          <q-card-section class="faq-item-text">
            <div class="faq-item" >
              <q-expansion-item
                :expand-icon="matKeyboardDoubleArrowDown"
                :expanded-icon="matKeyboardDoubleArrowUp"

                label="What is a backlink checker?"
                default-opened
                class="faq-item-header"
              >
                <q-card>
                  <q-card-section class="faq-item-text">
                  A backlink checker is a tool that analyzes the links pointing to your website. It helps identify link sources, quality, and potential issues affecting your SEO ranking.
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </div>
            <div class="faq-item" >
              <q-expansion-item
                :expand-icon="matKeyboardDoubleArrowDown"
                :expanded-icon="matKeyboardDoubleArrowUp"
                label="Why are backlinks important for SEO?"
                default-opened
                class="faq-item-header"
              >
                <q-card>
                  <q-card-section class="faq-item-text">
                  Backlinks signal to search engines that your site is trustworthy and valuable. High-quality backlinks improve domain authority, boost rankings, and drive more organic traffic.
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </div>
            <div class="faq-item" >
              <q-expansion-item
                :expand-icon="matKeyboardDoubleArrowDown"
                :expanded-icon="matKeyboardDoubleArrowUp"
                label="How does a backlink checker improve SEO?"
                default-opened
                class="faq-item-header"
              >
                <q-card>
                  <q-card-section class="faq-item-text">
                  It identifies toxic backlinks, reveals new opportunities, and ensures your link profile aligns with SEO best practices. Maintaining a strong backlink profile helps secure better search engine rankings.
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </div>
            <div class="faq-item" >
              <q-expansion-item
                :expand-icon="matKeyboardDoubleArrowDown"
                :expanded-icon="matKeyboardDoubleArrowUp"
                label="What makes a backlink high quality?"
                default-opened
                class="faq-item-header"
              >
                <q-card>
                  <q-card-section class="faq-item-text">
                  High-quality backlinks come from authoritative, relevant sites with good domain authority. They are earned organically and provide real value to users and search engines.
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </div>
            <div class="faq-item" >
              <q-expansion-item
                :expand-icon="matKeyboardDoubleArrowDown"
                :expanded-icon="matKeyboardDoubleArrowUp"
                label="How often should I check my backlinks?"
                default-opened
                class="faq-item-header"
              >
                <q-card>
                  <q-card-section class="faq-item-text">
                  Regularly, ideally monthly or quarterly. Frequent checks help identify harmful links, track link-building progress, and maintain a clean and effective backlink profile.
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </div>
            <div class="faq-item" >
              <q-expansion-item
                :expand-icon="matKeyboardDoubleArrowDown"
                :expanded-icon="matKeyboardDoubleArrowUp"
                label="What are toxic backlinks?"
                default-opened
                class="faq-item-header"
              >
                <q-card>
                  <q-card-section class="faq-item-text">
                  Toxic backlinks are links from spammy, irrelevant, or low-authority websites. They can harm your rankings and lead to penalties from search engines like Google.
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </div>
            <div class="faq-item" >
              <q-expansion-item
                :expand-icon="matKeyboardDoubleArrowDown"
                :expanded-icon="matKeyboardDoubleArrowUp"
                label="Can backlink maintenance prevent SEO penalties?"
                default-opened
                class="faq-item-header"
              >
                <q-card>
                  <q-card-section class="faq-item-text">
                  Yes, monitoring and removing toxic backlinks reduces the risk of Google penalties. A healthy backlink profile ensures compliance with search engine guidelines.
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </div>
            <div class="faq-item" >
              <q-expansion-item
                :expand-icon="matKeyboardDoubleArrowDown"
                :expanded-icon="matKeyboardDoubleArrowUp"
                label="What features should a backlink checker have?"
                default-opened
                class="faq-item-header"
              >
                <q-card>
                  <q-card-section class="faq-item-text">
                  Look for tools that provide link source details, domain authority scores, anchor text analysis, toxic link identification, and export options for easy reporting.
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </div>
            <div class="faq-item" >
              <q-expansion-item
                :expand-icon="matKeyboardDoubleArrowDown"
                :expanded-icon="matKeyboardDoubleArrowUp"
                label="Do free backlink checkers work well?"
                default-opened
                class="faq-item-header"
              >
                <q-card>
                  <q-card-section class="faq-item-text">
                  Free tools are great for basic checks but sometimes have limitations. Paid tools offer comprehensive data, detailed analysis, and advanced features for professional SEO.
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </div>
            <div class="faq-item" >
              <q-expansion-item
                :expand-icon="matKeyboardDoubleArrowDown"
                :expanded-icon="matKeyboardDoubleArrowUp"
                label="How does link-building impact SEO success?"
                default-opened
                class="faq-item-header"
              >
                <q-card>
                  <q-card-section class="faq-item-text">
                  Effective link-building improves domain authority, boosts keyword rankings, and increases organic traffic. A well-managed backlink profile is crucial for long-term SEO growth.
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </div>          
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </div>



  </div>

  
</template>

<style scoped>

  .faq{
    text-align: center;
    margin: 100px auto;
    max-width: 1000px;   
  }

  .faq-title-h2 {
    letter-spacing: unset;
    padding:unset;
    margin-block-end: 0px;
    margin-block-start: 0px;
    font-weight: 500;
    line-height:1.2em !important;
    font-size: 4rem;  
    color: #474747;
    margin:0 20px 20px 20px;
  }
  .faq-title-h4{
    padding:unset;
    font-size: 2rem;
    margin:0 20px 48px 20px;
  }
  .faq-item{
    text-align: left;
    width:100%;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 32px;
    line-height: 1.4;
    transition: transform 0.20s ease;
    padding: 16px 16px 16px 16px;    
    margin-bottom:16px;
  }
  
  .faq-item:hover{
    box-shadow: 0 32px 40px -16px rgba(25, 25, 25, 0.1);  
    transform: translate(0, -4px);
    opacity: 1;
    border: 1px solid #aaa;    
  }

  .faq-item-header{
    font-weight: 600;
    font-size: 1.75rem;    
  }

  .faq-item-section-header{
    font-weight: 600;
    font-size: 1.75rem;    
  }

  .faq-item-text{
    font-weight: 400;
    font-size: 1.5rem;
  }

  .faq-acme-wrapper{
    display:block;
    margin: 16px auto 0 auto; 
  }
  .faq-acme-wrapper-img{
    max-width: 100%; 
    height: auto;   
    display: block; 
    margin: auto auto;
  }


  @media (max-width: 620px) { 
    .faq-title-h2 {
      font-size: 3rem; 
      padding:0 20px 0 20px;
      margin-bottom: 10px;
    }
    .faq-title-h4 {
      font-size: 1.5rem; 
      padding:0 20px 0 20px;
      margin-bottom: 48px;
    }

    .faq-item-header{
      font-size: 1.5rem;    
    }
    .faq-item-section-header{
      font-size: 1.5rem;    
    }
    .faq-item-text{
      font-size: 1.25rem;
    }

    .faq-item{
      padding: 12px 12px 12px 12px;
      border-radius: 16px;
    }

  }

  @media (max-width:500px){
    .faq-item-header{
      font-size: 1.25rem;    
    }
    .faq-item-section-header{
      font-size: 1.25rem;    
    }
    .faq-item-text{
      font-size: 1rem;
    }

    .faq-item{
      padding: 8px 8px 8px 8px;
      
    }

  }

  body.body--dark .faq-title-h2 {
    color: #eee; /* 158bff, 1976d2 */
  }
  body.body--dark .faq-title-h4 {
    color: #ddd; /* 158bff, 1976d2 */
  }
  body.body--dark .faq-title-h5 {
    color: rgb(68,68,68); /* 158bff, 1976d2 */
  }

  body.body--dark .faq-item{
    color:#666;
    background-color: #aaa !important;
  }
  body.body--dark .faq-item-text{
    background-color: #aaa !important;
  }
  body.body--dark .faq-item-header{
    background-color: #aaa !important;
  }

  

</style>

<script setup>

  //import { getVisState, toggleVis } from '../utils.js';
  import { matKeyboardDoubleArrowUp, matKeyboardDoubleArrowDown } from '@quasar/extras/material-icons';
  //import { ref } from 'vue';

  const scrollToForm = () => {
    const element = document.getElementById('submitForm');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  //const defaultvis = true;              //default for this card
  //const localkey = 'faqVis';
  //const localvis = ref(defaultvis); 

  //getVisState(localvis, localkey, defaultvis);

  //refs (eg localvis) when used in a template get automatically unwrapped, need to call toggleVis from within script setup to maintain reactivity
  //const updateVis = (localKey) => {
  //  toggleVis(localvis, localKey);
  //}


</script>