module.exports = {
    projName            : 'BackLinkCheckers.org',                                          //used for disclaimer etc (actual name of project)
    projCopy            : '2024',
    projDomain          : 'backlinkcheckers.org',                          //used for cookie 
    projUrl             : 'https://backlinkcheckers.org/',                                 //base url
    projEmail           : 'toolsie@backlinkcheckers.org',
    projCompany         : 'Name Metrics Ltd',
    projCompanyDeets    : 'regd. in England company: 9908640, VAT: GB258989131',
    projCompanyEmail    : 'andy@namemetrics.com',
    projCookieName      : 'backlinkcheckers',
    projCookieExpire    : 3600*24*1000,
}